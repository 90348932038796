@import "../../../styles/breakpoints.scss";

.help {
	width: 100%;
	height: fit-content;
	margin-top: 10rem;
	padding: 0 280px;
	overflow: hidden;
	@include media("<=desktop") {
		margin-top: 5.5rem;
	}
	@include media("<=phone") {
		margin-top: 9rem;
		padding: 0 20px;
	}

	&__container {
		width: 100%;
		padding: 50px 100px;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		@include media("<=phone") {
			padding: 0;
			padding-bottom: 2rem;
			border: none;
		}
		h1 {
			font-family: var(--font-roboto);
			font-size: 3rem;
			line-height: 3rem;
			margin-bottom: 10px;
			span {
				font-weight: 200;
			}
			@include media("<=phone") {
				font-size: 2rem;
				line-height: 2rem;
			}
		}
		p.headingText {
			margin-top: 2rem;
			font-size: 22px;
			line-height: 35px;
			@include media("<=phone") {
				margin-top: 1rem;
				font-size: 17px;
				line-height: 24px;
			}
		}

		.questions {
			width: 100%;
			min-height: 100px;
			margin-top: 2rem;

			.accor {
				margin-bottom: 1.5rem;
				border: none;
				border-radius: 8px;
				@include media("<=phone") {
					margin-bottom: 10px;
				}
				.summary {
					border: 1px solid #ccc;
					padding: 0 2rem;
					@include media("<=phone") {
						padding: 0 10px;
					}
					&__block {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: space-between;
						.text {
							width: calc(100% - 60px);
							font-size: 1.2rem;
							line-height: 2rem;
							font-weight: 600;
							@include media("<=phone") {
								font-size: 15px;
								line-height: 22px;
							}
						}
						.logo {
							width: 60px;
							display: flex;
							align-items: center;
							justify-content: flex-end;
							font-size: 1.5rem;
							color: var(--primary-color);
							@include media("<=phone") {
								font-size: 15px;
							}
						}
					}
				}
				.details {
					border-left: 1px solid #ccc;
					border-right: 1px solid #ccc;
					border-bottom: 1px solid #ccc;
					padding: 1rem 2rem;
					@include media("<=phone") {
						padding: 15px;
					}
					p {
						font-size: 1rem;
						line-height: 1.7rem;
						@include media("<=phone") {
							font-size: 15px;
							line-height: 22px;
						}
					}
					p.answerB {
						margin-top: 1rem;
					}
				}
			}
		}
	}
}
