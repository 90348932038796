/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.transationDetail {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.2);
	z-index: 10;

	.wrapper {
		width: 100%;
		height: 100vh;
		background: transparent;
		position: relative;

		.info {
			width: 40%;
			height: 100vh;
			position: absolute;
			top: 0;
			right: 0;
			background: var(--white-color);
			animation: rightToLeft;
			animation-duration: 0.3s;
			overflow-y: auto;
			overflow-x: hidden;
			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			/* Hide scrollbar for Chrome, Safari and Opera */
			&::-webkit-scrollbar {
				display: none;
			}
			@include media("<=phone") {
				width: 100%;
			}

			//   ============= Content =============
			&__content {
				min-height: 10vh;
				margin-top: 5rem;
				padding: 7rem 2rem 2rem 2rem;
				position: relative;
				@include media("<=desktop") {
					margin-top: 2rem;
				}
				@include media("<=phone") {
					margin-top: 5rem;
					padding: 5rem 25px 25px 25px;
				}

				// ============= Close Button =============
				.closeButton {
					position: absolute;
					top: 4rem;
					right: 2rem;
					width: 50px;
					height: 50px;
					border-radius: 8px;
					background: var(--black-color);
					color: var(--primary-color);
					@include media(">=phone") {
						display: none;
					}
					@include media("<=phone") {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				// ============= Heading =============
				h2 {
					font-family: var(--font-roboto);
				}
				p {
					display: inline-block;
					padding: 5px 10px;
					border-radius: 5px;
					margin-top: 10px;
					font-size: 1rem;
					font-weight: bold;
					@include media("<=desktop") {
						font-size: 14px;
					}
				}
				p.orange {
					background: #fff4e4;
					color: #f7cb73;
				}
				p.red {
					background: #fdf4f6;
					color: red;
				}
				p.grey {
					background: #f2f2f2;
					color: grey;
				}
				p.green {
					background: #e5f3f1;
					color: #0ba080;
				}
				p.violet {
					background: #e8e6f0;
					color: #685490;
				}
				p.blue {
					background: #f0f3f7;
					color: #45b6fe;
				}
				p.default {
					background: #f2f2f2;
					color: grey;
				}

				.blocks {
					margin-top: 2rem;
					.block {
						width: 100%;
						display: flex;
						align-items: center;
						margin-bottom: 1.5rem;
						&__logo {
							width: 50px;
							height: 50px;
							border-radius: 8px;
							margin-right: 10px;
							display: flex;
							align-items: center;
							justify-content: center;
							box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
							@include media("<=desktop") {
								width: 35px;
								height: 35px;
								border-radius: 5px;
							}
						}
						&__name {
							font-size: 1rem;
							@include media("<=desktop") {
								font-size: 13px;
							}
							p {
								display: block;
								padding: 0;
								font-size: 12px;
								font-weight: normal;
								@include media("<=desktop") {
									font-size: 10px;
								}
							}
							h5 {
								display: block;
								font-size: 1rem;
								@include media("<=desktop") {
									font-size: 13px;
								}
							}
							span {
								padding: 0;
								margin-right: 10px;
								font-size: 12px;
								margin-top: 5px;
								@include media("<=desktop") {
									font-size: 10px;
								}
							}
							span.orange {
								color: #f7cb73;
							}
							span.green {
								color: #0ba080;
							}
							span.violet {
								color: #685490;
							}
							span.red {
								color: red;
							}
						}
					}

					.blockGroup {
						display: flex;
						justify-content: space-around;
						.block {
							width: 48%;
						}
						.sinfo {
							width: 48%;
						}
					}
				}

				h4 {
					margin-top: 2rem;
					margin-bottom: 1.5rem;
				}
				.sinfo {
					width: 100%;
					margin-bottom: 1rem;
					small {
						font-weight: bold;
					}
					p {
						padding: 0;
						font-size: 1rem;
						line-height: 1.5rem;
						font-weight: normal;
						@include media("<=desktop") {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
}

@keyframes rightToLeft {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}
