/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

// Data And Head
.head,
.data {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #ccc;
	div {
		padding: 5px 10px;
		@include media("<=desktop") {
			padding: 3px 8px;
		}
	}
	.id {
		width: 15%;
	}
	.username {
		width: 25%;
	}
	.sent {
		width: 15%;
	}
	.received {
		width: 15%;
	}
	.amount {
		width: 15%;
	}
	.status {
		width: 15%;
	}
}

// Head
.head {
	div {
		font-weight: bold;
		padding-bottom: 15px;
		font-size: 14px;
	}
}

// Data
.data {
	font-size: 1rem;
	font-weight: 500;
	@include media("<=desktop") {
		font-size: 14px;
	}

	.id {
		color: gray;
		transition: 0.3s;
		cursor: pointer;
		&:hover {
			color: #000;
		}
	}
	.status {
		padding: 0;
		span.pending {
			color: #f7cb73;
		}
		span.accept {
			color: #0ba080;
		}
		span.reject {
			color: red;
		}
		span.incomplit {
			color: #685490;
		}
		span.cancel {
			color: gray;
		}
		span.default {
			color: #cccccc;
		}
	}
}
