@import "../../../styles/breakpoints.scss";

.profile {
	width: 100%;
	margin-top: 9.5rem;
	@include media("<=desktop") {
		margin-top: 5.5rem;
	}
	@include media("<=phone") {
		margin-top: 9rem;
	}

	/* ======================== TRANSACTIONS ====================== */
	.table {
		width: 100%;
		padding: 50px 200px;
		@include media("<=desktop") {
			padding: 40px 130px;
		}
		@include media("<=phone") {
			width: 100%;
			padding: 20px 25px;
		}
		h1 {
			font-family: var(--font-roboto);
			font-size: 3rem;
			line-height: 3rem;
			margin-bottom: 10px;
			@include media("<=phone") {
				font-size: 2rem;
				line-height: 2rem;
			}
			span {
				font-weight: normal;
			}
		}
		p {
			font-size: 1rem;
			line-height: 1.4rem;
			@include media("<=phone") {
				font-size: 1rem;
				line-height: 1.3rem;
			}
		}
		.formobileonly {
			@include media(">phone") {
				display: none;
			}
		}

		.head {
			margin-top: 2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			&__left {
				h3 {
					@include media("<=phone") {
						font-size: 1rem;
						margin-bottom: -1rem;
					}
				}
			}
			&__option {
				width: 50%;
				display: flex;
				justify-content: flex-end;
				@include media("<=phone") {
					display: none;
				}
				div {
					width: fit-content;
					padding: 10px 20px;
					border-radius: 3px;
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
					font-size: 12px;
					font-weight: 500;
					display: flex;
					align-items: center;
					span {
						margin-right: 10px;
						margin-top: 5px;
					}
					&:not(:last-child) {
						margin-right: 15px;
					}
					&:not(:first-child) {
						width: 40px;
						height: 40px;
						justify-content: center;
					}
				}
			}
		}
	}
}
