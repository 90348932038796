/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.epi {
	margin: 0.5rem 0;

	width: 100%;
	height: fit-content;
	padding: 2rem 3rem;
	background: var(--white-color);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	@include media("<=desktop") {
		padding: 2rem;
	}
	@include media("<=phone") {
		width: 94%;
		padding: 2rem 2rem;
		margin: 1.5rem auto;
	}
	h2 {
		font-size: 1.5rem;
		font-family: var(--font-roboto);
		margin-bottom: 10px;
		@include media("<=desktop") {
			font-size: 1.3rem;
		}
	}

	&__info {
		width: 100%;
		margin-top: 2rem;

		.infoContainer {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			&:not(:last-child) {
				margin-bottom: 1.5rem;
				@include media("<=phone") {
					margin-bottom: 0;
				}
			}
			.info {
				width: 48%;
				display: flex;
				@include media("<=phone") {
					width: 100%;
					margin-bottom: 1.5rem;
				}
				&__logo {
					width: 40px;
					height: 40px;
					margin-right: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 6px;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
					@include media("<=desktop") {
						width: 32px;
						height: 32px;
						margin-right: 10px;
					}
					img {
						width: 30px;
						@include media("<=desktop") {
							width: 25px;
						}
					}
				}
				&__text {
					width: calc(100% - 55px);
					@include media("<=desktop") {
						width: calc(100% - 42px);
					}
					p {
						font-size: 12px;
						text-transform: uppercase;
					}
					h5 {
						font-weight: 500;
						font-size: 1rem;
						@include media("<=desktop") {
							font-size: 13px;
						}
					}
				}
			}
		}
	}
}
