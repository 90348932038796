@import "../../styles/breakpoints.scss";

h4 {
  margin-bottom: 1rem;
  font-family: var(--font-roboto);
  display: flex;
  align-items: center;
  div:nth-child(1) {
    font-size: 1.1rem;
    margin-right: 0.5rem;
  }
}
p {
  line-height: 1.3rem;
}

.notify, .varified{
  padding: 2rem;
  border-radius: 10px;
}

.notify {
  background: #fff9ea;
  h4 div:nth-child(1) {
    color: #f28627;
  }
  button {
    margin-top: 2rem;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    background: #f28627;
    border-radius: 5px;
    padding: 15px 30px;
    font-size: 1rem;
    outline: none;
    border: none;
    cursor: pointer;
  }
}

.varified{
  background: rgba(242, 131, 34, 0.1);
  h4{
    color: var(--primary-color);
  }
  ul{
    list-style: none;
    li{
      font-size: 14px;
      line-height: 25px;
      span{
        margin-right: 1rem;
        margin-bottom: 1rem;
        color: var(--primary-color);
        @include media("<=phone") {
          font-size: 1rem;
        }
      }
    }
  }
}