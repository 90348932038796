@import "../../styles/breakpoints.scss";

.pagenotfound {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;

  &__wrapper {
    width: 50%;
    height: 50vh;
    position: relative;
    @include media("<=phone") {
      width: 100%;
    }
    .emoji {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 25rem;
      height: 25rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f2f5f8;
      @include media("<=phone") {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 10rem;
        height: 10rem;
      }
      img {
        width: 45%;
      }
    }
    .text {
      position: absolute;
      left: 20rem;
      top: 50%;
      transform: translateY(-50%);
      @include media("<=phone") {
        width: 100%;
        top: 9em;
        left: 50%;
        transform: translateX(-50%);
      }
      h1 {
        font-size: 5rem;
        line-height: 5rem;
        @include media("<=phone") {
          font-size: 2.5rem;
          text-align: center;
        }
      }
      h2 {
        font-weight: normal;
        font-size: 2rem;
        margin-bottom: 1rem;
        @include media("<=phone") {
          font-size: 1rem;
          margin-bottom: 10px;
          text-align: center;
        }
      }
      p {
        color: gray;
        font-size: 1rem;
        @include media("<=phone") {
          font-size: 14px;
          text-align: center;
        }
      }
      a {
        display: block;
        margin-top: 1.5rem;
        text-decoration: none;
        color: var(--primary-color);
        font-size: 1.2rem;
        font-weight: bold;
        @include media("<=phone") {
          text-align: center;
        }
      }
    }
  }
}
