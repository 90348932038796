@import "../../../styles/breakpoints.scss";

.terms {
	width: 100%;
	height: fit-content;
	margin-top: 10rem;
	padding: 0 280px;
	overflow: hidden;
	@include media("<=desktop") {
		margin-top: 5.5rem;
	}
	@include media("<=phone") {
		margin-top: 9rem;
		padding: 0 20px;
	}

	&__container {
		width: 100%;
		padding: 50px 200px;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		@include media("<=phone") {
			padding: 0;
			padding-bottom: 2rem;
			border: none;
		}
		h1 {
			font-family: var(--font-roboto);
			font-size: 3rem;
			line-height: 3rem;
			margin-bottom: 10px;
			span {
				font-weight: 200;
			}
			@include media("<=phone") {
				font-size: 2rem;
				line-height: 2rem;
			}
		}
		p.headingText {
			margin-top: 2rem;
			font-size: 22px;
			line-height: 32px;
			text-align: justify;
			@include media("<=phone") {
				margin-top: 1rem;
				font-size: 17px;
				line-height: 24px;
			}
		}
		p.headingTextB {
			margin-top: 1.5rem;
			font-size: 18px;
			line-height: 30px;
			@include media("<=phone") {
				margin-top: 1rem;
				font-size: 15px;
				line-height: 24px;
			}
		}

		.block {
			margin-top: 2.5rem;
			h2 {
				font-size: 2rem;
				margin-bottom: 1.5rem;
				@include media("<=phone") {
					font-size: 1.7rem;
				}
			}
			p {
				font-size: 1.2rem;
				line-height: 1.7rem;
				margin-bottom: 1.5rem;
				text-align: justify;
				span {
					font-size: 1rem;
					display: block;
					margin-top: 1rem;
					color: #555;
				}
				span.numb {
					display: inline-block;
					font-size: 1.2rem;
					font-weight: bold;
					margin-right: 10px;
					color: #000;
				}
				@include media("<=phone") {
					font-size: 17px;
					line-height: 24px;
					margin-bottom: 1.5rem;
				}
			}
		}
	}
}
