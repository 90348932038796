@import "../../styles/breakpoints.scss";

.transactionTableGlobal {
	background: var(--white-color);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: 2rem;
	padding: 4rem 5rem;
	@include media("<=desktop") {
		margin-top: 5rem;
		padding: 50px 120px;
	}
	@include media("<=phone") {
		width: 95%;
		margin: 0 auto;
		height: fit-content;
		margin-top: 2rem;
		padding: 3rem 1rem;
		margin-bottom: -6em !important;
	}
	h3 {
		margin-bottom: 2rem;
		font-size: 1.5rem;
	}

	.tableWrapper {
		width: 100%;
		@include media("<=phone") {
			overflow-y: auto;
			overflow-x: scroll;
		}

		.table {
			justify-content: center;
			@include media("<=phone") {
				width: 100%;
			}
			.head,
			.data {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #ccc;
				padding: 1rem 0;
				div {
					padding: 5px 10px;
				}
				.sent,
				.received {
					width: 25%;
					display: flex;
					align-items: center;
					img {
						height: 30px;
						margin-right: 1rem;
						@include media("<=phone") {
							height: 20px;

							margin-left: -0.6rem;
						}
					}
				}
				.amount {
					width: 25%;
					@include media("<=phone") {
						width: 17%;
						text-align: center;
						margin-right: 1rem;
					}
				}
				.status {
					width: 30%;
					padding: 0;
				}
			}

			// Head
			.head {
				div {
					font-weight: bold;
					font-size: 1.2rem;
					@include media("<=phone") {
						padding-bottom: 0.5rem;
						padding-right: 5rem;
						font-size: 0.6rem;
					}
				}
			}

			// Data
			.data {
				font-size: 1rem;
				font-weight: 600;
				@include media("<=phone") {
					font-size: 0.6rem;
				}
				.status {
					width: 15%;
					display: flex;
					align-items: center;
					@include media("<=phone") {
						display: flex;
						align-items: center;
						width: 30%;
						font-size: 0.7rem;

						display: flex;
						justify-content: center;
					}
					&__text {
						width: fit-content;
						border-radius: 10px;
						text-align: center;
						@include media("<=phone") {
							width: calc(100% - 1.5rem);
						}
					}
					.green {
						align-items: center;
						height: 2.3em;
						background-color: rgb(44, 137, 25);
						color: #fff;
					}
					.violet {
						background-color: #685490;
						color: #fff;
					}
					.red {
						background-color: #191929;
						color: #fff;
					}
					.blue {
						background-color: #0b3a77;
						color: #fff;
					}
					.orange {
						background-color: #f29f05;
						color: #fff;
					}
					.gray {
						background-color: gray;
						color: #fff;
					}
				}
			}
		}
	}
}

.emptyBlock {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 40vh;
	border: 1px solid #ccc;
	border-radius: 8px;
	@include media("<=phone") {
		min-height: 30vh;
	}
	div {
		h1,
		p {
			text-align: center;
		}
		span {
			font-size: 5rem;
			color: #ccc;
			@include media("<=phone") {
				font-size: 3.5rem;
			}
		}
		h1 {
			margin-top: 2rem;
			font-family: var(--font-roboto);
			@include media("<=desktop") {
				margin-top: 1rem;
				font-size: 1.5rem;
			}
		}
	}
}
