/* ============== BREAKPOINTS ============= */
@import "../../../styles/breakpoints.scss";

.exchange {
	width: 100%;
	min-height: fit-content;
	&__form {
		margin-top: 2rem;
		width: 100%;
		height: fit-content;
		padding: 2rem;
		background: var(--white-color);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		@include media("<=phone") {
			box-shadow: none;
			margin-top: 1rem;
			padding: 0 25px;
		}

		.accountNumber {
			width: 100%;
			height: 7rem;
			margin-bottom: 2rem;
			border-radius: 8x;
			// box-shadow: 0 0 10px rgba(242, 131, 34, 0.4);;
			background: rgba(242, 131, 34, 0.1);
			
			display: flex;
			@include media("<=desktop") {
				height: 6rem;
				
				border-radius: 6px;
			}
			@include media("<=phone") {
				padding: 2rem;
				height: fit-content;
			}

			.image {
				width: 30%;
				position: relative;
				@include media("<=phone") {
					width:0%
					// display: none;
				}
				img {
					height: 180%;
					position: absolute;
					top: -3.5rem;
					left: 0;
					@include media("<=desktop") {
						top: -2.8rem;
					}
					@include media("<=phone") {
						top: 5.5rem;
						left: -4rem;
						height: 35%;
						
						
					}
				}
			}
			.text {
				width: 70%;
				padding: 1rem;
				@include media("<=desktop") {
					padding: 12px;
				}
				@include media("<=phone") {
					padding: 0;
					width: 100%;
				}
				p {
					text-align: right;
					@include media("<=phone") {
						text-align: center;
						padding: 0;
					}
					span {
						font-weight: bold;
					}
				}
				h1 {
					text-align: right;
					font-size: 2rem;
					font-family: var(--font-lato);
					color: var(--primary-color);
					@include media("<=desktop") {
						font-size: 1.6rem;
					}
					@include media("<=phone") {
						font-size: 1.5rem;
						text-align: center;
						padding: 0;
					}
				}
				@include media("<=desktop") {
					padding: 1rem;
				}
			}
		}
	}
}
