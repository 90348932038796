/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.buttonGroup {
  width: 100%;
  padding: 0 !important;
  .select {
    width: 100%;
    position: relative;
    &__title {
      height: 40px;
      background: transparent;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      z-index: 10;
      padding: 0 !important;
      @include media("<=desktop") {
        height: 50px;
        font-size: 14px;
      }
      @include media("<=phone") {
        font-size: 12px;
      }
      div {
        padding: 0;
        span {
          margin-right: 10px;
        }
      }
      span.orange {
        color: #f7cb73;
      }
      span.red {
        color: red;
      }
      span.grey {
        color: grey;
      }
      span.green {
        color: #0ba080;
      }
      span.violet {
        color: #685490;
      }
      span.blue {
        color: #45b6fe;
      }
      span.default {
        color: grey;
      }
      img{
        width: 20px;
        margin-right: 10px;
      }
    }
    &__options {
      width: calc(100% - 10px);
      height: fit-content;
      background: var(--white-color);
      margin-top: 5px;
      transition: all 0.4s;
      border: 1px solid #ccc;
      border-radius: 0 0 5px 5px;
      position: absolute;
      top: 45px;
      left: 10px;
      padding: 0;
      transform: scaleY(0);
      z-index: 10;
      @include media("<=desktop") {
        top: 50px;
      }
      @include media("<=phone") {
        width: 100%;
        left: -10px;
      }
      .option {
        display: block;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.4s;
        &:not(:last-child) {
          border-bottom: 1px solid #ccc;
        }
        @include media("<=desktop") {
          padding: 6px 10px;
        }
        @include media("<=phone") {
          font-size: 12px;
        }
        &:hover {
          background: #f2f2f2;
        }
        .oname {
          width: 85%;
        }
        .ocheck {
          width: 15%;
          @include media("<=phone") {
            width: 10px;
            padding: 0;
          }
          span{
            @include media("<=phone") {
              margin-right: 5px;
            } 
          }
          span.orange {
            color: #f7cb73;
          }
          span.red {
            color: red;
          }
          span.grey {
            color: grey;
          }
          span.green {
            color: #0ba080;
          }
          span.violet {
            color: #685490;
          }
          span.blue {
            color: #45b6fe;
          }
          span.default {
            color: grey;
          }
        }
      }
    }
    @keyframes openMenu {
      0% {
        transform: scaleY(0);
      }
      50% {
        transform: scaleY(1.1);
      }
      100% {
        transform: scaleY(1);
      }
    }
    @keyframes closeMenu {
      0% {
        transform: scaleY(1);
      }
      50% {
        transform: scaleY(1.1);
      }
      100% {
        transform: scaleY(0);
      }
    }
    .open {
      animation: openMenu 300ms ease-in-out forwards;
    }
    .close {
      animation: closeMenu 300ms ease-in-out forwards;
    }
  }
}
