/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.popupWrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .popup{
        width: 30%;
        min-height: 40vh;
        background: var(--white-color);
        text-align: center;
        padding: 2rem;
        border-radius: 10px;
        position: relative;
        animation: scaleAnim;
        animation-duration: 0.3s;
        @include media("<=phone") {
            width: 90%;
          }
        img{
            width: 50%;
        }
        h4{
            width: fit-content;
            margin: auto;
            text-align: center !important; 
            font-family: var(--font-roboto);
            font-size: 1.5rem;
            margin-bottom: .5rem;
        }
        p{
            text-align: center;
        }
        button{
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            outline: none;
            border: none;
            background: var(--black-color);
            color: var(--primary-color);
            border-radius: 5px 10px 5px 5px;
            cursor: pointer;
            font-size: 1.5rem;
        }
        a{
            display: inline-block;
            width: fit-content;
            margin: auto;
            margin-top: 2rem;
            text-decoration: none;
            padding: 10px 30px;
            border-radius: 5px;
            background-color: var(--primary-color);
            color: var(--white-color);
            cursor: pointer;
        }
    }
}

@keyframes scaleAnim{
    from{
        transform: scale(0);
    }
    to{
        transform: scale(1);
    }
}