/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

form {
	width: 100%;
	height: fit-content;
	padding: 0.5rem 0;

	display: flex;
	flex-direction: column;
	@include media("<=desktop") {
		margin-top: -1rem;
	}

	.inputGroup {
		padding: 0.5rem;
		width: 100%;
		margin-bottom: 0.5rem;
		position: relative;
		@include media("<=phone") {
			margin-bottom: 0rem;
		}
		label {
			display: block;
			font-size: 14px;
			margin-bottom: 10px;
			span {
				font-weight: 500;
			}
			@include media("<=desktop") {
				font-size: 0.8rem;
				margin-bottom: 0;
				font-weight: 600;
				padding: 0.8rem;
			}
		}
		input {
			width: 100%;
			height: fit-content;
			padding: 1.1rem 3rem;
			background: var(--white-color);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.092);
			border-radius: 10px;

			padding: 15px 20px;
			font-size: 1rem;
			margin-bottom: 0.5rem;
			border: none;

			@include media("<=desktop") {
				font-size: 14px;
				outline: none;
			}
			@include media("<=phone") {
				font-size: 0.7rem;
				border: none;
				margin-bottom: 0.5rem;
				margin-top: 0.5rem;
			}
		}
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			background: var(--white-color);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.092) inset !important;
			border-radius: 10px;

			// -webkit-box-shadow: 0 0 0 30px white inset !important;
		}
		input.haveerror {
			border: 1px solid red;
		}
		.eye {
			width: inline-block;
			position: absolute;
			top: 47px;
			right: 20px;
			font-size: 1.2rem;
			cursor: pointer;
			@include media("<=desktop") {
				top: 38px;
				font-size: 15px;
			}
		}
	}
	small {
		font-size: 12px;
		color: rgb(50, 194, 84);
	}
	small.errorMessage {
		font-size: 14px;
		margin-bottom: 1rem;
	}

	button {
		width: fit-content;
		outline: none;
		border: none;
		border-radius: 10px;
		padding: 15px 20px;
		font-size: 1rem;
		background: var(--black-color);
		color: #fff;
		cursor: pointer;
		@include media("<=desktop") {
			font-size: 14px;
			margin-top: -1rem;
		}
		@include media("<=phone") {
			font-size: 1rem;
			margin: 0.5rem;
			padding: 1rem;
			margin-left: 0.9rem;
		}
	}
}
