@import "../../styles/breakpoints.scss";

.profileForm {
  width: 100%;
  min-height: fit-content;
  padding: 2.5rem 4rem;
  @include media("<=phone") {
    padding: 0;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .inputGroup {
      width: 100%;
      margin-bottom: 2rem;
      position: relative;
      @include media("<=phone") {
        margin-bottom: 1.2rem;
      }
      label {
        display: block;
        font-size: 14px;
        margin-bottom: 10px;
      }
      input {
        width: 100%;
        outline: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 15px 20px;
        font-family: var(--font-quick);
        margin-bottom: 5px;
        background: var(--white-color);
        color: var(--black-color);
        @include media("<=phone") {
          font-size: 1rem;
        }
      }
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      input.haveerror {
        border: 1px solid red;
      }
      .eye {
        width: inline-block;
        position: absolute;
        top: 47px;
        right: 20px;
        font-size: 1.2rem;
        cursor: pointer;
      }
    }
    small {
      font-size: 12px;
      color: red;
    }
    small.errorMessage {
      font-size: 14px;
      margin-bottom: 1rem;
    }
    button {
      width: fit-content;
      background: var(--black-color);
      color: var(--white-color);
      border-radius: 5px;
      padding: 15px 30px;
      font-size: 1rem;
      outline: none;
      border: none;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
