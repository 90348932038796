/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.marqueeText {
	width: 100%;
	position: fixed;
	top: 7rem;
	left: 0;
	padding: 1rem;
	// background: rgba(242, 131, 34, 0.3);
	background: #ffff;
	// color: var(--primary-color);
	box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
	font-size: 0.9rem;
	z-index: 10;

	span {
		color: var(--primary-color);
		margin: 0 1.5rem;
	}
	@include media("<=desktop") {
		top: 6em;

		font-size: 0.82em;
	}
	@include media("<=phone") {
		top: 5.5em;
		left: 0;
		padding: 1.1em;
	}
}
