/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.exchangeFormLoader {
	margin-top: 2rem;
	width: 100%;
	height: fit-content;
	padding: 2rem;

	@include media("<=phone") {
		box-shadow: none;
		margin-top: 1rem;
		padding: 0 25px;
	}

	h3 {
		margin-top: 2rem;
		@include media("<=desktop") {
			margin-top: 1rem;
		}
	}

	form {
		width: 100%;
		margin: 1rem auto;
		position: relative;
		.inputGroup {
			width: 100%;
			display: flex;
			justify-content: space-between;
			@include media("<=phone") {
				flex-wrap: wrap;
			}

			.drop {
				width: 45%;
				@include media("<=desktop") {
					width: 48%;
				}
				@include media("<=phone") {
					width: 100%;
					&:not(:last-child) {
						margin-bottom: 1rem;
					}
				}
			}
		}

		.calculation {
			margin-top: 2rem;
			width: 100%;
		}
		.calculation2 {
			margin-top: 1rem;
			width: 100%;
		}
		.button {
			width: 100%;
			margin-top: 2rem;
		}
	}
}
