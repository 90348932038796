/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.exchWrapper {
	background: var(--white-color);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border: none;
	border-radius: 10px;
	width: 100%;
	min-height: fit-content;
	margin: 6rem auto;
	padding: 6rem 3rem;
	padding-bottom: 0 !important;
	margin-bottom: 0 !important;
	display: flex;
	// @include media("<=desktop") {
	// 	margin-top: 5rem;
	// 	padding: 3.5rem 15rem;
	// }
	@include media("<=phone") {
		margin-top: 6rem;
		flex-wrap: wrap;
		padding: 0;
	}
}

.formWrapper {
	width: 60%;
	margin: 1rem 0;
	padding: 4rem 2rem;
	margin-bottom: 0rem !important;

	min-height: fit-content;

	@include media("<=desktop") {
		padding-right: 1rem;
	}
	@include media("<=phone") {
		background: var(--white-color);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border: none;
		border-radius: 10px;
		width: 100%;
		min-height: fit-content;
		// padding-right: 0;
		margin-bottom: 0.5rem;
		margin-top: -2rem;
		// padding-left: 0;
		padding: 5rem 0 0 0;
	}
	h1 {
		font-family: var(--font-roboto);
		font-size: 3rem;
		line-height: 3rem;
		margin-bottom: 2.5rem;
		@include media("<=desktop") {
			font-size: 2.5rem;
			line-height: 2.5rem;
		}
		@include media("<=phone") {
			font-size: 2rem;
			line-height: 2rem;
			padding: 0 1rem;
		}
	}
	h1.head {
		position: relative;
		&::before {
			content: "";
			position: absolute;
			bottom: -1rem;
			left: 0;
			width: 25%;
			height: 3px;
			background: var(--primary-color);
			@include media("<=phone") {
				left: 1.2rem;
				bottom: -15px;
			}
		}
	}
	p {
		margin-bottom: 2rem;
		font-size: 1rem;
		line-height: 1.4rem;
		@include media("<=desktop") {
			font-size: 14px;
		}
		@include media("<=phone") {
			padding: 0 0.8rem;
		}
	}
}
.msgWrapper {
	width: 40%;
	min-height: fit-content;
	padding-left: 2rem;

	@include media("<=desktop") {
		padding-left: 1rem;
	}
	@include media("<=phone") {
		width: 100%;
		padding-left: 0;
		min-height: fit-content;
	}
}

// -----------------------------------------------------phone---------------------------------------------------------------------
@media (max-width: 500px) {
}
// -----------------------------------------------------tab---------------------------------------------------------------------
@media (min-width: 501px) and (max-width: 768px) {
}
// ----------------------------------------------------laptop---------------------------------------------------------------------
@media (min-width: 769px) and (max-width: 768px) {
}
