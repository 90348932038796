@import "../../../styles/breakpoints.scss";

.editProfile {
	width: 100%;
	min-height: 100vh;
	margin-top: 8.5rem;
	padding: 50px 180px;
	@include media("<=phone") {
		margin-top: 8rem;
		padding: 25px;
	}

	h1 {
		font-family: var(--font-roboto);
		font-size: 3rem;
		line-height: 3rem;
		margin-bottom: 10px;
		span {
			font-weight: 200;
		}
		@include media("<=phone") {
			font-size: 2rem;
			line-height: 2rem;
		}
	}

	.info {
		width: 100%;
		min-height: 75vh;
		margin-top: 2rem;
		display: flex;
		flex-wrap: wrap;
		border-radius: 10px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		@include media("<=phone") {
			box-shadow: none;
			flex-direction: column-reverse;
		}
		&__form {
			width: 60%;
			min-height: 70vh;
			border-radius: 10px 0 0 10px;
			@include media("<=phone") {
				width: 100%;
				min-height: fit-content;
				margin-top: 2rem;
			}
			.options {
				padding: 2rem 0 0 4rem;
				display: flex;
				@include media("<=phone") {
					padding: 1rem 0 0 0;
					margin-bottom: 2rem;
				}
				div {
					margin-right: 2rem;
					padding-bottom: 5px;
					cursor: pointer;
					transition: 0.5s;
				}
				div.active {
					position: relative;
					font-weight: 500;
					&::after {
						content: "";
						position: absolute;
						bottom: -5px;
						left: 0;
						width: 60%;
						height: 3px;
						background: var(--primary-color);
					}
				}
			}
		}
		&__notify {
			width: 40%;
			min-height: 70vh;
			border-left: 1px solid rgba(0, 0, 0, 0.1);
			border-radius: 0 10px 10px 0;
			padding: 2rem;
			@include media("<=phone") {
				width: 100%;
				padding: 0;
				min-height: fit-content;
			}
			.design {
				margin-top: 2rem;
				border: 1px solid #ccc;
				border-radius: 10px;
				padding: 0;
				position: relative;
				img {
					width: 100%;
					border-radius: 10px;
				}
				@include media("<=phone") {
					display: none;
				}
			}
		}
	}
}
