@import "../../styles/breakpoints.scss";

.message {
  padding: 2rem;
  border-radius: 10px;
  background: #f7f7f7;
  @include media("<=phone") {
    padding: 1rem;
  }
  ul {
    margin-top: 1rem;
    margin-left: 1rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
}
