@import "../../styles/breakpoints.scss";

.nidForm {
  width: 100%;
  min-height: fit-content;
  padding: 2.5rem 4rem;
  @include media("<=phone") {
    padding: 0;
  }

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
  }

  //   ========================== IMAGE FIELD ==========================
  .inputField {
    background: #f6f7fa;
    border: 1px dashed #ccc;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;

    .notUploadedBlock {
      // padding: 1rem;
      .logo {
        text-align: center;
        font-size: 3.5rem;
        color: #e7e9f1;
      }
      p {
        text-align: center;
        font-size: 0.8rem;
      }
    }
  }
  //   ========================== Uploading ==========================
  .uploading{
    width: 100%;
    min-height: 40vh;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    img{
      margin-top: -2rem;
      width: 35%;
    }
    h3{
      font-size: 1.2rem;
      font-weight: normal;
      text-align: center;
      margin-top: -2rem;
    }
    h1{
      margin-top: 2rem;
    }
  }
  //   ========================== IMAGE PREVIEW ==========================
  .previewImage {
    width: 100%;
    height: 45vh;
    text-align: center;
    background: #f6f7fa;
    border: 1px solid #ccc;
    border-radius: 10px;
    img {
      height: 100%;
    }
  }

  small{
    margin-top: 1.2rem;
    display: block;
    color: red;
    font-weight: bold;
  }

  //   ========================== IMAGE DATA ==========================
  .imageData {
    border: 1px solid var(--primary-color);
    margin-top: 10px;
    padding: 0.8rem 1rem;
    border-radius: 8px;
    position: relative;
    &__name {
      display: flex;
      align-items: center;
      .logo {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(242, 131, 34, 0.1);
        color: var(--primary-color);
      }
      .name {
        margin-left: 10px;
        h5 {
          font-size: 14px;
        }
        p {
          font-size: 12px;
          color: gray;
        }
      }
    }
    &__progress {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .progress {
        width: calc(90% - 50px);
        margin-left: 50px;
        height: 6px;
        border-radius: 10px;
        background-color: var(--primary-color);
        animation: progress;
        animation-duration: 1.5s;
      }
      .status {
        color: var(--primary-color);
      }
    }

    .deleteButton {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
  }

  .imageError{
    border: 1px solid red;
    &__name{
      .logo{
        background: rgba(255, 0, 0, 0.05);
        color: red
      }
    }
    &__progress{
      .progress{
        background-color: red;
      }
      .status{
        color: red;
      }
    }
  }

  .nidNumbField {
    margin-bottom: 2rem;
    input {
      width: 100%;
      outline: none;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 15px 20px;
      font-family: var(--font-quick);
      font-size: 1rem;
      margin-bottom: 5px;
      background: var(--white-color);
      color: var(--black-color);
    }
  }
}

.buttonBox {
  margin-top: 2rem;
  button {
    padding: 15px 20px;
    outline: none;
    border: none;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  .cancel {
    margin-right: 1.5rem;
    padding: 15px 30px;
  }
  .submitButton {
    background: var(--black-color);
    color: var(--white-color);
  }
}

@keyframes progress {
  from {
    width: 0%;
  }
  to {
    width: calc(90% - 50px);
  }
}
