/* ============== BREAKPOINTS ============= */
@import "../../../styles/breakpoints.scss";

/* ============== SIGNIN ============= */
.signup {
	width: 100%;
	min-height: calc(100vh - 3rem);
	margin-top: 9.5rem;
	display: flex;
	@include media("<=desktop") {
		margin-top: 5.5rem;
	}
	@include media("<=phone") {
		margin-top: 4.5rem;
		padding-bottom: 2rem;
	}

	&__form {
		width: 50%;
		padding: 6rem 6rem 6rem 7rem;
		@include media("<=desktop") {
			padding: 5rem 4rem 5rem 6rem;
		}
		@include media("<=phone") {
			width: 100%;
			padding: 5rem 25px 0 25px;
		}
		h1 {
			font-family: var(--font-roboto);
			font-size: 3rem;
			line-height: 3.5rem;
			margin-bottom: 10px;
			@include media("<=desktop") {
				font-size: 2.5rem;
				line-height: 3rem;
			}
			@include media("<=phone") {
				font-size: 2rem;
				line-height: 2.5rem;
				margin-bottom: 1em;
			}
		}
		p {
			width: 70%;
			font-family: var(--font-quick);
			font-size: 17px;
			line-height: 22px;
			@include media("<=desktop") {
				width: 80%;
				font-size: 1rem;
			}
			@include media("<=phone") {
				font-size: 0.8rem;
				font-weight: 600;
			}
		}
		form {
			width: 100%;
			margin-top: 3rem;
			display: flex;
			flex-direction: column;
			@include media("<=desktop") {
				margin-top: 2em;
			}
			.inputGroup {
				width: 100%;
				margin-bottom: 2rem;
				position: relative;
				@include media("<=phone") {
					margin-bottom: 1.2rem;
				}
				@include media("<=phone") {
					margin-bottom: 1.2rem;
				}
				label {
					display: block;
					font-size: 14px;
					margin-bottom: 10px;
				}
				input {
					background: var(--white-color);
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.092);
					border-radius: 10px;
					border: none;
					width: 100%;
					outline: none;

					border-radius: 5px;
					padding: 15px 20px;
					font-size: 20px;
					font-family: var(--font-quick);
					margin-bottom: 5px;
					background: var(--white-color);
					color: var(--black-color);
					@include media("<=desktop") {
						font-size: 14px;
					}
					@include media("<=phone") {
						font-size: 1rem;
					}
				}
				/* Change the white to any color */
				input:-webkit-autofill,
				input:-webkit-autofill:hover,
				input:-webkit-autofill:focus,
				input:-webkit-autofill:active {
					-webkit-box-shadow: 0 0 0 30px rgba(109, 103, 103, 0.186) inset !important;
				}
				input.haveerror {
					border: 1px solid red;
				}
				small {
					font-size: 12px;
					color: red;
				}
				.eye {
					width: inline-block;
					position: absolute;
					top: 47px;
					right: 20px;
					font-size: 1.2rem;
					cursor: pointer;
					@include media("<=desktop") {
						top: 38px;
						font-size: 15px;
					}
				}
			}
			button {
				outline: none;
				border: none;
				border-radius: 5px;
				padding: 15px 70px;
				font-size: 20px;
				background: var(--black-color);
				color: #fff;
				cursor: pointer;
				@include media("<=desktop") {
					width: 100%;
					margin-top: 0.5rem;
					font-size: 14px;
				}
				@include media("<=phone") {
					margin: 0 auto;
					font-size: 1rem;
				}
			}
		}
		p.lastText {
			margin-top: 1.5rem;
			font-size: 15px;
			@include media("<=desktop") {
				margin-top: 1rem;
				font-size: 14px;
			}
			a {
				text-decoration: none;
				color: var(--primary-color);
				font-weight: bold;
			}
		}
	}

	&__style {
		width: 50%;
		padding: 20px;
		height: calc(100vh - 5rem);
		position: sticky;
		top: 5rem;
		.wrapper {
			height: 100%;
			border-radius: 10px;
			background: #f7f7f7;
			position: relative;
		}
		@include media("<=phone") {
			display: none;
		}
	}
}
