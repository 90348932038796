.rateTable {
	width: 100%;
	height: fit-content;

	.head,
	.data {
		width: 100%;
		display: flex;
		align-items: center;
		div {
			width: 100%;
			height: 3rem;
			font-size: 0.7rem;
			font-weight: 600;
			display: flex;
			align-items: center;

			padding: 0.6em;
			border-bottom: 1px solid #ccc;
		}

		.image {
			width: 5em;
		}
		.name {
			width: calc(40% - 6em);
		}
		.b_rate {
			margin: 0 0.2em;
			height: 2.2em;
			border-radius: 1em;

			font-size: 0.8rem;

			width: 18%;
			background-color: rgb(237, 25, 25);
			color: rgb(247, 247, 247);
		}
		.s_rate {
			margin: 0 0.2em;
			height: 2.2em;
			border-radius: 1em;

			font-size: 0.8rem;

			width: 18%;
			background-color: rgb(237, 25, 25);
			color: rgb(247, 247, 247);
		}
		.reserve {
			margin: 0 0.2em;
			height: 2.5em;
			border-radius: 1em;

			font-size: 0.8rem;

			width: 24%;
			background-color: rgb(44, 137, 25);
			color: rgb(251, 251, 251);
		}
	}
	.head {
		div {
			font-size: 0.9rem;
			font-weight: 600;
		}
	}
	.data {
		.image {
			img {
				height: 30px;
			}
		}
	}
}
