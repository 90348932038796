/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.welcomeSlides {
  width: 70%;
  height: 70vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include media("<=desktop") {
    width: 60%;
  }

  .slide {
    width: 100%;
    min-height: 60vh;
    img {
      width: 60%;
      margin: auto;
    }
    h2 {
      text-align: center;
      font-family: var(--font-roboto);
      font-size: 1.6rem;
      margin-bottom: 1rem;
      @include media("<=desktop") {
        font-size: 1.3rem;
      }
    }
    p {
      width: 70%;
      margin: auto;
      text-align: center;
      font-size: 1rem;
      line-height: 1.5rem;
      @include media("<=desktop") {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
