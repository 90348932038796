/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.inputGroup__input {
	width: 45%;
	@include media("<=desktop") {
		width: 48%;
	}
	@include media("<=phone") {
		width: 100%;
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}
	label {
		font-weight: 600;
		font-size: 14px;
		@include media("<=desktop") {
			font-size: 12px;
			font-weight: 600;
		}
	}
	.select {
		width: 100%;
		margin-top: 10px;
		position: relative;
		@include media("<=desktop") {
			margin-top: 10px;
		}
		&__title {
			height: 60px;

			outline: none;
			width: 100%;
			height: fit-content;
			padding: 0.7rem 1.5rem;
			background: var(--white-color);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			@include media("<=desktop") {
				padding: 1.7rem 1.5rem;
			}

			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			z-index: 10;
			@include media("<=desktop") {
				height: 50px;
				font-size: 14px;
			}
			.logo {
				width: 15%;
				margin-right: 20px;
				img {
					width: 35px;
					height: 35px;
					@include media("<=desktop") {
						width: 25px;
						height: 25px;
					}
				}
			}
			.name {
				width: 90%;
				font-weight: 500;
			}
		}
		&__options {
			width: 100%;
			height: fit-content;
			background: var(--white-color);
			margin-top: 5px;
			transition: all 0.4s;

			border-radius: 0 0 5px 5px;
			position: absolute;
			top: 65px;
			left: 0;
			transform: scaleY(0);
			z-index: 10;
			@include media("<=desktop") {
				top: 50px;
			}
			.option {
				height: fit-content;
				padding: 1.1rem 3rem;
				background: var(--white-color);
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

				display: block;
				padding: 15px 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				cursor: pointer;
				transition: 0.4s;
				border-radius: 10px;
				@include media("<=desktop") {
					margin-top: 0.3rem;
					font-size: 0.9rem;
					padding: 0.8rem 1rem;
					border-radius: 10px;
					background: var(--white-color);
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				}
				&:hover {
					background: #f2f2f2;
				}
				.ocheck {
					width: 10%;
					display: flex;
					align-items: center;
					div {
						width: 20px;
						height: 20px;
						border-radius: 50%;
						background-color: var(--primary-color);
						color: var(--white-color);
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				.ologo {
					width: 15%;
					margin-right: 10px;
					img {
						height: 35px;
						width: 35px;
						@include media("<=desktop") {
							width: 25px;
							height: 25px;
						}
					}
				}
				.oname {
					width: 75%;
				}
			}
		}
		@keyframes openMenu {
			0% {
				transform: scaleY(0);
			}
			50% {
				transform: scaleY(1.1);
			}
			100% {
				transform: scaleY(1);
			}
		}
		@keyframes closeMenu {
			0% {
				transform: scaleY(1);
			}
			50% {
				transform: scaleY(1.1);
			}
			100% {
				transform: scaleY(0);
			}
		}
		.open {
			animation: openMenu 300ms ease-in-out forwards;
		}
		.close {
			animation: closeMenu 300ms ease-in-out forwards;
		}
	}

	.error {
		border: 1px solid red;
	}
}
