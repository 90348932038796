@import "./breakpoints.scss";
@import "./variables.scss";

/* FOR ALL COMPONENTS */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* SETUP WEBSITE BACKGROUND COLOR */
body,
html {
	background: var(--white-color);
	font-family: Arial, Helvetica, sans-serif;
	color: var(--black-color);
}

/*  WHATS APP ICONS */
a.whatsapp_float {
	position: fixed;
	bottom: 2.5em;
	right: 19px;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 0.3s;
	font-size: 40px;
	background: #12d65a;
	color: white;
	img {
		width: 40px;
		height: 40px;
	}
	&:hover {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	}
}
a.app_float {
	position: fixed;
	bottom: 0.75em;
	right: 19px;
	width: 60px;
	height: 60px;
	border-radius: 30%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: 0.3s;
	font-size: 40px;
	background: #17b0e3;
	color: white;
	img {
		width: 40px;
		height: 40px;
	}
	&:hover {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	}
}
