@import "../../styles/breakpoints.scss";

.container2 {
	width: 97%;
	margin: 2rem auto;
	height: fit-content;
	font-weight: 500;
	padding: 4rem 2rem;
	background: var(--white-color);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding-bottom: 2.5rem !important;
	// margin-bottom: 2rem !important;
	@include media("<=desktop") {
		padding: 3rem;
		height: fit-content;
	}
	@include media("<=phone") {
		width: 94%;
		font-size: 0.9rem;

		margin: 9em auto;
		margin-bottom: 2em !important;

		background: var(--white-color);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		padding: 2rem 0rem;
	}

	h2 {
		font-size: 2rem;
		margin-left: 2rem;
		font-family: var(--font-roboto);
		margin-bottom: 10px;
		@include media("<=desktop") {
			font-size: 1.7rem;

			padding: 1rem;
		}
	}
	.desc2 {
		padding: 2rem 2rem;
		@include media("<=phone") {
			padding: 0 1.5rem;
		}
	}
}
