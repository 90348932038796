/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

// ============= Data And Head  =============
.head,
.data {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  div {
    padding: 15px 10px;
  }
  .id {
    width: 10%;
  }
  .name {
    width: 25%;
  }
  .email {
    width: 25%;
  }
  .phone {
    width: 20%;
  }
  .status {
    width: 10%;
  }
}

// ============= Head =============
.head {
  div {
    font-weight: bold;
    padding-bottom: 15px;
    @include media("<=desktop") {
      font-size: 14px;
    }
  }
}

// ============= Data =============
.data {
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.4s;
  @include media("<=desktop") {
    font-size: 14px;
  }
  &:hover {
    background: #f7f7f7;
  }

  .id {
    color: gray;
  }
  .status {
    display: flex;
    align-items: center;
    padding: 0;
    @include media("<=desktop") {
      font-size: 13px;
    }
    @include media("<=phone") {
      font-size: 0.9rem      ;
    }
    &__color {
      margin-top: 5px;
      height: 10px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      @include media("<=desktop") {
        width: 12px;
      }
      .orange {
        color: #f7cb73;
      }
      .green {
        color: #0ba080;
      }
      .violet {
        color: #685490;
      }
      .red {
        color: red;
      }
    }
  }
  .role {
    p {
      display: inline-block;
      padding: 3px 8px;
      border-radius: 5px;
      font-size: 13px;
      font-weight: bold;
      @include media("<=desktop") {
        font-size: 10px;
      }
    }
    p.superAdmin {
      background: #e6f7f1;
      color: #30b182;
    }
    p.admin {
      background: #fff7e6;
      color: #f29f05;
    }
    p.customer {
      background: #f2f4f8;
      color: #385e91;
    }
  }
}
