/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

/* ============== FOOTER ============= */
.footer {
	width: 100%;
	padding: 100px 180px;
	display: flex;
	flex-wrap: wrap;
	background: var(--black-color);
	@include media("<=desktop") {
		padding: 2em;
	}

	@include media("<=phone") {
		padding: 3rem 20px;
	}

	/* ============== COMMON HEADING ============= */
	h2 {
		font-size: 1.5rem;
		font-family: var(--font-roboto);
		color: var(--white-color);
		margin-bottom: 2rem;
		@include media("<=phone") {
			margin-bottom: 1.2rem;
		}
	}
	.links {
		display: flex;
		flex-direction: column;
		a {
			width: fit-content;
			display: inline-block;
			text-decoration: none;
			color: var(--white-color);
			&:not(:last-child) {
				margin-bottom: 1.5rem;
				@include media("<=phone") {
					margin-bottom: 1rem;
				}
			}
		}
		a.activeLink {
			position: relative;
			&::before {
				content: "";
				position: absolute;
				bottom: -8px;
				width: 50%;
				height: 2px;
				background: var(--primary-color);
			}
		}
	}

	&__one {
		width: 35%;
		@include media("<=phone") {
			width: 100%;
			margin-bottom: 2.2rem;
		}
		.logo {
			font-size: 3rem;
			font-family: var(--font-roboto);
			color: var(--white-color);
			margin-bottom: 2rem;
			@include media("<=phone") {
				margin-bottom: 1.2rem;
			}
			img {
				width: 7rem;
				@include media("<=phone") {
					width: 5rem;
				}
			}
		}
		.item {
			display: flex;
			align-items: center;
			margin-bottom: 1rem;
			.itemIcon {
				margin-right: 10px;
				color: var(--primary-color);
			}
			.itemText {
				font-family: var(--font-quick);
				font-size: 1rem;
				text-decoration: none;
				color: var(--white-color);
				a {
					text-decoration: none;
					color: var(--white-color);
					transition: 0.3s;
					&:hover {
						color: var(--primary-color);
					}
				}
			}
		}
	}
	&__two {
		width: 20%;
		@include media("<=phone") {
			width: 100%;
			margin-bottom: 2.2rem;
		}
	}
	&__three {
		width: 20%;
		@include media("<=phone") {
			width: 100%;
			margin-bottom: 2.2rem;
		}
	}
	&__four {
		width: 25%;
		@include media("<=phone") {
			width: 100%;
			margin-bottom: 2.2rem;
		}
		.socialLinks {
			display: flex;
			margin-bottom: 2rem;
			.link {
				width: 50px;
				height: 50px;
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: var(--primary-color);
				color: var(--white-color);
				font-size: 20px;
				text-decoration: none;
				transition: 0.4s;
				cursor: pointer;
				@include media("<=phone") {
					width: 40px;
					height: 40px;
					border-radius: 5px;
					font-size: 16px;
				}
				&:not(:last-child) {
					margin-right: 20px;
				}
				&:hover {
					transform: scale(1.1);
				}
			}
		}
		p {
			font-family: var(--font-quick);
			font-size: 1rem;
			color: var(--white-color);
			@include media("<=phone") {
				font-size: 14px;
			}
		}
	}
}
