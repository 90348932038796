@import "../../../styles/breakpoints.scss";

.contact {
	width: 100%;
	min-height: calc(100vh - 5rem);
	margin-top: 8rem;
	margin-bottom: 3rem;
	display: flex;
	flex-wrap: wrap;
	@include media("<=desktop") {
		margin-top: 7.5rem;
	}

	&__left {
		width: 50%;
		padding-top: 4rem;
		padding-left: 200px;
		@include media("<=desktop") {
			padding-top: 3rem;
		}
		@include media("<=phone") {
			width: 100%;
			padding-left: 25px;
			padding-top: 2rem;
		}
		.text {
			width: 100%;
			h1 {
				font-size: 3.5rem;
				line-height: 3.5rem;
				font-family: var(--font-roboto);
				@include media("<=desktop") {
					font-size: 2.5rem;
					line-height: 3rem;
				}
				@include media("<=phone") {
					font-size: 2rem;
					line-height: 2rem;
				}
			}
			p {
				margin-top: 1rem;
				display: flex;
				font-size: 1rem;
				a {
					display: inline-block;
					text-decoration: none;
					color: var(--black-color);
					position: relative;
					margin-left: 4px;
					font-weight: 500;
					font-size: 1rem;
					@include media("<=phone") {
						font-size: 13px;
					}
					&::after {
						content: "";
						position: absolute;
						bottom: -2px;
						left: 0;
						width: 100%;
						height: 1px;
						background: var(--primary-color);
					}
				}
			}
		}

		.image {
			margin-top: 6rem;
			width: 100%;
			@include media("<=phone") {
				display: none;
			}
			img {
				width: 40%;
			}
		}
	}

	&__right {
		width: 50%;
		padding-top: 4rem;
		padding-right: 200px;
		@include media("<=phone") {
			width: 100%;
			padding-right: 25px;
			padding-left: 25px;
			padding-top: 1rem;
		}
		form {
			width: 100%;
			height: fit-content;
			margin-top: 3rem;
			display: flex;
			flex-direction: column;
			@include media("<=desktop") {
				margin-top: 2.5rem;
			}
			.inputGroup {
				width: 100%;
				margin-bottom: 2rem;
				position: relative;
				@include media("<=phone") {
					margin-bottom: 1.2rem;
				}
				@include media("<=phone") {
					margin-bottom: 1.2rem;
				}
				label {
					display: block;
					font-size: 14px;
					margin-bottom: 10px;
				}
				input,
				textarea {
					width: 100%;
					outline: none;
					border: 1px solid #ccc;
					border-radius: 5px;
					padding: 15px 20px;
					font-size: 1rem;
					font-family: var(--font-quick);
					margin-bottom: 5px;
					background: var(--white-color);
					color: var(--black-color);
					@include media("<=desktop") {
						font-size: 14px;
					}
					@include media("<=phone") {
						font-size: 1rem;
					}
				}
				/* Change the white to any color */
				input:-webkit-autofill,
				input:-webkit-autofill:hover,
				input:-webkit-autofill:focus,
				input:-webkit-autofill:active {
					-webkit-box-shadow: 0 0 0 30px white inset !important;
				}
				input.haveerror {
					border: 1px solid red;
				}
				small {
					font-size: 12px;
					color: red;
				}
			}
			button {
				outline: none;
				border: none;
				border-radius: 5px;
				padding: 15px 70px;
				font-size: 20px;
				background: var(--black-color);
				color: #fff;
				cursor: pointer;
				@include media("<=desktop") {
					font-size: 14px;
				}
				@include media("<=phone") {
					width: 100%;
					margin-top: 0.5rem;
					font-size: 1rem;
				}
			}
		}
	}
}
