/* ============== BREAKPOINTS ============= */
@import "../../../styles/breakpoints.scss";

/* ============== SIGNIN ============= */
.signin {
	width: 100%;
	min-height: calc(100vh - 5rem);
	margin-top: 8.5rem;
	display: flex;
	@include media("<=desktop") {
		margin-top: 4.5rem;
	}
	@include media("<=phone") {
		padding: 2.5em 0;
		min-height: calc(100vh - 32em);
		width: 100%;
	}

	&__form {
		width: 50%;
		padding: 6rem 6rem 6rem 7rem;
		@include media("<=desktop") {
			padding: 5rem 4rem 5rem 6rem;
		}
		@include media("<=phone") {
			width: 100%;
			padding: 3em 1.5em;
			margin-bottom: -9em !important;
		}
		h1 {
			font-family: var(--font-roboto);
			font-size: 3rem;
			line-height: 3.5rem;
			margin-bottom: 1.8rem;
			@include media("<=desktop") {
				font-size: 2.5rem;
				line-height: 3rem;
			}
			@include media("<=phone") {
				font-size: 2rem;

				margin-bottom: 1em;
			}
		}
		p {
			font-family: var(--font-quick);

			font-size: 1rem;
			font-weight: 500;

			span {
				color: var(--primary-color);
				margin-right: 1rem;
			}
			@include media("<=desktop") {
				font-size: 1rem;
			}
			@include media("<=phone") {
				font-size: 0.9rem;
				font-weight: 500;

				span {
					color: var(--primary-color);
					margin-right: 1rem;
				}
			}
		}
		form {
			width: 100%;
			margin-top: 2rem;
			display: flex;
			flex-direction: column;
			@include media("<=desktop") {
				margin-top: 2em;
			}
			.inputGroup {
				width: 100%;
				margin-bottom: 2rem;
				position: relative;
				@include media("<=phone") {
					margin-bottom: 2rem;
				}
				label {
					display: block;
					font-size: 1.5rem;
					margin-bottom: 1rem;
					@include media("<=desktop") {
						padding: 0.1em 0.4em;
						font-size: 1rem;
					}
				}
				input {
					background: var(--white-color);
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.092);
					border-radius: 10px;
					border: none;
					width: 100%;
					outline: none;
					border: 1px solid #ccc;
					border-radius: 5px;
					padding: 15px 20px;
					font-size: 1rem;
					font-family: var(--font-quick);
					margin-bottom: 5px;
					background: var(--white-color);
					color: var(--black-color);
					@include media("<=desktop") {
						font-size: 14px;
					}
					@include media("<=phone") {
						font-weight: 500;
						border-radius: 10px;
						border: none;
						font-size: 0.9rem;
					}
				}
				input:-webkit-autofill,
				input:-webkit-autofill:hover,
				input:-webkit-autofill:focus,
				input:-webkit-autofill:active {
					-webkit-box-shadow: 0 0 0 30px rgba(90, 87, 87, 0.155) inset !important;
				}
				input.haveerror {
					border: 1px solid red;
				}
				.eye {
					width: inline-block;
					position: absolute;
					top: 3.1em;
					right: 20px;
					font-size: 1.2rem;
					cursor: pointer;
					@include media("<=desktop") {
						top: 3.2em;
						font-size: 1em;
					}
				}
			}
			small {
				font-size: 1rem;
				color: red;
			}
			small.errorMessage {
				font-size: 14px;
				margin-bottom: 1rem;
			}
			button {
				outline: none;
				border: none;
				border-radius: 5px;
				padding: 15px 70px;
				font-size: 20px;
				background: var(--black-color);
				color: #fff;
				cursor: pointer;
				@include media("<=desktop") {
					font-size: 14px;
					margin-top: -1rem;
				}
				@include media("<=phone") {
					padding: 1em;
					width: 100%;
					margin: 0.5rem auto;
					font-size: 1rem;
				}
			}
		}
		p.lastText {
			margin-top: 1.5rem;
			font-size: 1.5rem;
			@include media("<=desktop") {
				margin-top: 1rem;
				font-size: 1.1rem;
			}
			a {
				text-decoration: none;
				color: var(--primary-color);
				font-weight: bold;
			}
		}
	}

	&__style {
		width: 50%;
		padding: 4em;
		height: calc(100vh - 5rem);
		.wrapper {
			height: 100%;
			border-radius: 10px;
			background: var(--white-color);
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			position: relative;

			img.line1,
			img.line2 {
				width: 100px;
				position: absolute;
			}
			img.line1 {
				top: 0;
				right: 0;
			}
			img.line2 {
				bottom: 0;
				left: 0;
				transform: rotate(180deg);
			}
		}
		@include media("<=phone") {
			display: none;
		}
	}
}
