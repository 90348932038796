/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.container {
	width: 100%;
	margin: 0em 0;
	height: fit-content;
	font-weight: 600;
	padding: 4em 3rem;
	background: var(--white-color);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding-bottom: 3em !important;
	margin-bottom: 2rem !important;
	@include media("<=desktop") {
		padding: 3rem;
		height: fit-content;
	}

	@include media("<=phone") {
		width: 94%;
		font-size: 1rem;

		margin: 1.5em auto;

		background: var(--white-color);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		padding: 3rem 1.2rem;
	}

	h2 {
		font-size: 1.5rem;
		text-align: center;
		font-family: var(--font-roboto);
		margin-bottom: 10px;
		@include media("<=desktop") {
			font-size: 1rem;
			padding-left: 0.5rem;
		}
	}
	p {
		font-size: 1rem;
		font-weight: 500;
		line-height: 1rem;
		@include media("<=desktop") {
			font-size: 10px;
		}
	}
}

.exchange__desclaimer {
	margin-top: 3rem;
	@include media("<=desktop") {
		margin-top: 2em;
	}
	.desc {
		display: flex;
		margin-bottom: 1.2rem;
		font-size: 0.8rem;
		line-height: 1.2rem;
		.descNumb {
			// width: 10%;
			margin-right: 1.2rem;
			@include media("<=desktop") {
				margin-right: 1rem;
			}
			div {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background: var(--primary-color);
				color: var(--white-color);
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 1rem;
				@include media("<=desktop") {
					width: 30px;
					height: 30px;
					font-size: 14px;
				}
			}
		}
		.descText {
			a {
				text-decoration: none;
				font-weight: bold;
				color: rgb(146, 12, 12);
				transition: 0.3s;
				&:hover {
					font-weight: bold;
				}
			}
		}

		.descText2 {
			font-weight: 600;
			font-size: 0.9rem;
			padding: 0 7em;
			text-align: center;

			a {
				text-decoration: none;
				// font-weight: bold;

				color: var(--primary-color);
				transition: 0.3s;
				// &:hover {
				// 	font-weight: bold;
				// }
			}
		}
	}
	.extra {
		background: var(--black-color);
		color: var(--white-color);
		border-radius: 10px;
		margin-top: 2rem;
		padding: 1.5rem;
		@include media("<=desktop") {
			padding: 1rem;
		}
		p {
			font-size: 0.8rem;
			line-height: 1.2rem;
			@include media("<=desktop") {
				font-size: 14px;
			}
			span {
				text-decoration: none;
				// color: var(--primary-color);
				font-weight: bold;
			}
		}
	}
}
