/* ============== BREAKPOINTS ============= */
@import "../../../styles/breakpoints.scss";

.users {
	width: 100%;
	margin-top: 8.5rem;
	padding: 50px 120px;
	@include media("<=desktop") {
		margin-top: 8rem;
	}
	@include media("<=phone") {
		width: 100%;
		padding: 20px 25px;
	}

	//   ============= PRIMARY HEADING =============
	h1 {
		font-family: var(--font-roboto);
		font-size: 3rem;
		line-height: 3rem;
		margin-bottom: 10px;
		@include media("<=desktop") {
			font-size: 2.5rem;
			line-height: 2.5rem;
		}
		@include media("<=phone") {
			font-size: 2rem;
			line-height: 2rem;
		}
		span {
			font-weight: normal;
		}
	}
	p {
		font-size: 1rem;
		line-height: 1.4rem;
		@include media("<=phone") {
			font-size: 1rem;
			line-height: 1rem;
		}
	}

	/* ============= TRANSACTIONS TABLE ============= */
	.tableWrapper {
		width: 100%;
		padding: 0;
		position: sticky;
		margin-top: 2rem;
		margin-bottom: 6rem;
		top: 7rem;
		@include media("<=phone") {
			width: 100%;
			margin-bottom: 4rem;
			overflow-x: scroll;

			margin-top: 0;
			top: 3rem;
		}
		.table {
			width: 100%;
			margin-top: 2rem;
			@include media("<=phone") {
				width: 300%;
			}
		}
	}
}

// ============= EMPTY BLOCK =============
.emptyBlock {
	width: 50%;
	margin: auto;
	text-align: center;
	padding: 4rem;
	@include media("<=phone") {
		width: 100%;
		text-align: left;
		margin: 0;
		padding: 0;
	}
	img {
		height: 50vh;
		margin-top: -5rem;
		@include media("<=phone") {
			height: 45vh;
			margin-top: 0;
			margin-left: -2rem;
		}
	}
	.text {
		margin-top: -4rem;
		h2 {
			font-family: var(--font-roboto);
			@include media("<=phone") {
				text-align: center;
			}
		}
		p {
			width: 70%;
			margin: auto;
			@include media("<=phone") {
				width: 100%;
				margin: 0;
				margin-top: 1rem;
				line-height: 1.5rem;
				font-size: 1.2rem;
				text-align: center;
			}
		}
	}
}

/* ============= PAGINATION ============= */
.paginationBttns {
	width: 100%;
	height: 40px;
	margin-top: 4rem;
	list-style-type: none;
	display: flex;
	@include media("<=phone") {
		height: fit-content;
		margin-bottom: 1rem;
	}
}
.paginationBttns a {
	text-decoration: none;
	padding: 10px 15px;
	margin: 8px;
	border-radius: 5px;
	border: 1px solid gray;
	color: #222;
	cursor: pointer;
	transition: 0.4s;
	@include media("<=phone") {
		padding: 7px;
		font-size: 12px;
	}
}
.paginationActive a {
	background: var(--primary-color);
	border: none;
	color: var(--white-color);
}
.nextsBtn,
.previousBtn {
	color: #fff !important;
	background: var(--black-color);
	transition: 0.3s;
	font-weight: bold;
	&:hover {
		color: var(--primary-color) !important;
	}
}
