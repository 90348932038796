@import "../../../styles/breakpoints.scss";

.rates {
	background: var(--white-color);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: 2rem;
	padding: 4rem 5rem;
	@include media("<=desktop") {
		margin-top: 5rem;
		padding: 50px 120px;
	}
	@include media("<=phone") {
		width: 95%;

		margin: 0 auto;
		height: fit-content;
		margin-top: 8em;
		padding: 4em 0.3em;
		margin-bottom: 2rem;
	}

	h1 {
		font-family: var(--font-roboto);
		font-size: 3rem;
		line-height: 3rem;
		margin-bottom: 1em;
		margin-left: 1em;
		span {
			font-weight: 200;
		}
		@include media("<=phone") {
			font-size: 1.8rem;
			line-height: 2rem;
		}
	}

	.tableWrapper {
		width: 100%;
		@include media("<=phone") {
			overflow-x: scroll;
			overflow-y: auto;
		}
		.table {
			width: 100%;
			margin-top: 2rem;
			margin-bottom: 2rem;
			@include media("<=phone") {
				margin-top: 1rem;
				width: 100%;
			}
		}
	}
}
