/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

/* ============== NAV ============= */
.nav {
	width: 100vw;
	height: 7rem;
	padding: 0 4rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--white-color);
	box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	@include media("<=desktop") {
		height: 5rem;

		padding: 0 90px;
	}
	@include media("<=1100px") {
		padding: 0 70px;
	}
	@include media("<=phone") {
		height: 4.5em;
		padding: 0 1em;
		min-height: 3em;
	}

	&__brand {
		display: flex;
		align-items: center;
		@include media("<=phone") {
			width: fit-content;
		}
		a {
			text-decoration: none;
			color: var(--black-color);
			img {
				height: 4rem;
				@include media("<=desktop") {
					height: 3em;
				}
				@include media("<=phone") {
					height: 2.5em;
					padding-left: 0.5em;
				}
			}
		}
	}
	// .itemText {
	// 	font-size: 0.7em;
	// 	background-color: red;
	// 	margin: 0.5em;
	// 	display: flex;
	// 	display: float;
	// }
	.online {
		display: flex;
		align-items: center;
		&__status {
			display: flex;
			align-items: center;
			background: #f7f7f7;
			padding: 0.5em 1em;
			border-radius: 10px;
			margin-right: 2rem;
			@include media("<=desktop") {
				padding: 5px 10px;
				border-radius: 10px;
				margin-right: 1.5rem;
			}
			@include media("<=phone") {
				padding: 0.5rem 1rem;
				border-radius: 10px;
				margin-right: 0;
			}
			.head {
				display: flex;
				align-items: center;
				margin-right: 1rem;
				font-weight: bold;
				font-size: 0.8rem;
				@include media("<=desktop") {
					font-size: 12px;
				}
				div {
					&:nth-child(1) {
						margin-right: 8px;
						font-weight: bold;
						font-size: 1.1rem;
						@include media("<=desktop") {
							font-size: 20px;
						}
					}
				}
			}

			.stat {
				display: flex;
				align-items: center;
				padding: 10px;
				background: #e0e0e0;
				border-radius: 10px;
				font-size: 0.8rem;
				@include media("<=desktop") {
					padding: 6px;
					font-size: 12px;
				}
				@include media("<=phone") {
					padding: 5px;
					font-size: 0.7rem;
					padding-right: 0.8rem;
				}
				div {
					&:nth-child(1) {
						margin-right: 8px;
						color: var(--primary-color);
					}
				}
				div.green {
					color: #31cc46;
				}
				div.red {
					color: red;
				}
			}
		}
		&__worktime {
			font-size: 0.9rem;
			span {
				font-weight: bold;
			}
			@include media("<=desktop") {
				font-size: 10px;
			}
			@include media("<=phone") {
				display: none;
			}
		}
	}

	&__links {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		@include media("<=phone") {
			width: fit-content;
		}
		a {
			display: block;
			text-decoration: none;
			margin-left: 0.5rem;
			color: var(--black-color);
			font-weight: 500;
			padding: 0.5rem 1rem;
			position: relative;
			outline: none;
			-webkit-tap-highlight-color: transparent;
			span {
				font-size: 10px;
				color: #f29f05;
			}
			&:last-child {
				background: var(--primary-color);
				color: var(--white-color);
				border-radius: 5px;
			}
			@include media("<=desktop") {
				margin-left: 6px;
				font-size: 13px;
				padding: 0.6rem;
			}
			@include media("<=1100px") {
				margin-left: 0.8rem;
				padding: 0.5rem 1rem;
				font-size: 0.9rem;
			}
			@include media("<=phone") {
				display: none;
			}
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				width: 0;
				height: 2px;
				background: var(--primary-color);
				transition: 0.3s;
			}
			&:hover::before {
				width: 50%;
			}
		}
		a.activeLink {
			position: relative;
			outline: none;
			-webkit-tap-highlight-color: transparent;
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				width: 50%;
				height: 2px;
				background: var(--primary-color);
			}
		}
		.dashboard {
			margin-left: 1rem;
			padding: 0.5rem 1rem;
			border-radius: 5px;
			display: flex;
			background: var(--primary-color);
			color: var(--white-color);
			cursor: pointer;
			font-weight: 500;
			position: relative;
			transition: 0.3s;
			outline: none;
			-webkit-tap-highlight-color: transparent;
			div:nth-child(1) {
				margin-right: 0.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			div:nth-child(3) {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				position: absolute;
				top: -5px;
				right: -5px;
				background: #f28627;
			}
			@include media("<=desktop") {
				padding: 0.5rem;
				font-size: 13px;
			}
			@include media("<=1100px") {
				margin-left: 0.8rem;
				font-size: 13px;
			}
			@include media("<=phone") {
				display: none;
			}
			&:hover {
				background: #c92617;
			}
		}
	}

	.menuWrapper {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
	}
	.dashboardMenu {
		position: fixed;
		top: 5rem;
		right: 10rem;
		min-height: 20vh;
		width: 15%;
		background: var(--white-color);
		z-index: 100;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		padding: 2rem 1.5rem;
		display: flex;
		flex-direction: column;
		@include media("<=desktop") {
			width: 20%;
		}
		@include media("<=1100px") {
			width: 24%;
		}
		@include media("<=990px") {
			width: 30%;
		}
		@include media("<=880px") {
			width: 35%;
		}
		a,
		div {
			width: fit-content;
			display: flex;
			align-items: center;
			text-decoration: none;
			color: var(--black-color);
			transition: 0.5s;
			padding: 0.5rem;
			cursor: pointer;
			outline: none;
			-webkit-tap-highlight-color: transparent;
			span {
				margin-right: 1rem;
				&:nth-child(2) {
					margin-left: 10px;
					color: #f28627;
					font-size: 10px;
				}
			}

			&:not(:last-child) {
				margin-bottom: 5px;
			}
			&:hover {
				font-weight: 500;
			}
		}
	}
}

/* ============== TOGGLE BUTTON ============= */
.btn__toggler {
	width: 100%;
	height: fit-content;
	padding: 1.1rem 3rem;
	background: var(--white-color);
	box-shadow: 0 0 10px rgba(173, 183, 178, 0.863);
	border-radius: 10px;

	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 30px;
	width: 35px;
	padding: 0;
	background: transparent;
	border: none;
	cursor: pointer;
	box-sizing: border-box;

	border-radius: 5px;
	padding: 5px 8px;
}
.btn__toggler:focus {
	outline: none;
}
.btn__togglerLine {
	width: 100%;
	height: 1px;
	background: #000;
}

/* ============== SIDE DRAWER ============= */
.side__drawer {
	height: 100vh;
	width: 70%;
	max-width: 400px;
	background: white;
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	transform: translateX(-110%);
	transition: 0.3s ease-out;
	z-index: 1500;
	overflow: hidden;
}
.side__drawer.open {
	transform: translateX(0);
}
.Drawerheading {
	width: 100%;
	padding: 20px;
	display: flex;
	align-items: center;
}
.Drawerheading a img {
	width: 50px;
}
.drawerLink {
	.up {
		padding: 2rem 0;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	div,
	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		width: 100%;
		color: #222;
		margin-bottom: 1rem;
		padding: 0.5rem 1.5rem;
		font-weight: 500;
		outline: none;
		-webkit-tap-highlight-color: transparent;

		span {
			margin-right: 10px;
			display: block;
			&:nth-child(2) {
				color: #f28627;
				font-size: 10px;
				margin-left: 1rem;
			}
		}

		&:hover {
			text-decoration: none;
			color: #222;
		}
	}
	.activeLink {
		border-right: 5px solid var(--primary-color);
		color: var(--primary-color);
	}
}
.backdrop {
	height: 100vh;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 100;
}
.backdrop-white {
	background: transparent;
}
@media (min-width: 769px) {
	.btn__toggler {
		display: none;
	}
}
@media (max-width: 768px) {
	.nav-items {
		display: none;
	}
}
