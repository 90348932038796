/* ============== BREAKPOINTS ============= */
@import "../../../styles/breakpoints.scss";

.exchange {
	width: 100%;
	min-height: calc(100vh - 3em);

	&__form {
		margin-top: 1rem;
		width: 100%;
		height: fit-content;
		padding: 0rem;

		@include media("<=phone") {
			width: 100%;

			margin-top: 1rem;
			padding: 0 0.9rem;
		}

		h3 {
			font-family: var(--font-roboto);
			font-size: 1.2rem;
			margin-top: 2rem;
			@include media("<=desktop") {
				font-size: 1rem;
				margin-top: 1rem;
			}
		}
		form {
			width: 100%;
			margin-top: 1rem;
			position: relative;
			.inputGroup {
				width: 100%;
				display: flex;
				justify-content: space-between;
				@include media("<=phone") {
					flex-wrap: wrap;
				}
			}

			.calculation {
				margin-top: 1.2rem;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				.calc_50 {
					width: 100%;
					@include media("<=desktop") {
						width: 100%;
					}
					@include media("<=phone") {
						width: 100%;
					}
					label {
						display: block;
						font-size: 14px;
						margin-bottom: 10px;
						@include media("<=desktop") {
							font-size: 12px;
							margin-bottom: 6px;
						}
					}
					input {
						background: var(--white-color);
						box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
						border: none;
						width: 100%;
						height: 60px;
						background: var(--white-color);
						padding: 0 1.5em;
						outline: none;
						margin-top: 1em;

						border-radius: 10px;
						display: flex;
						align-items: center;
						font-size: 1.5em;
						@include media("<=desktop") {
							height: 50px;
							font-size: 1em;
						}
					}
					small {
						display: block;
						color: red;
						margin-top: 10px;
						span {
							margin-right: 5px;
						}
					}
					.calc_amount {
						background: var(--white-color);
						box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
						border: none;
						min-height: 20vh;
						margin-top: 2rem;
						padding: 2.5rem 4.5rem;

						border-radius: 80px;
						position: relative;
						overflow: hidden;
						h4 {
							font-size: 14px;
							line-height: 14px;
							font-weight: normal;
							@include media("<=desktop") {
								font-size: 1rem;
								line-height: 1.2rem;
								margin-bottom: 1rem;
								margin-top: 1em;
							}
						}

						.rec_amount {
							padding: 0;
							font-size: 3rem;
							line-height: 4rem;
							@include media("<=desktop") {
								font-size: 2.5rem;
								line-height: 3.5rem;
								
							}
							span {
								font-size: 1rem;
								margin-right: 10px;
								@include media("<=desktop") {
									font-size: 14px;
									margin-right: 6px;
								}
							}
						}
						p {
							padding: 0;
							font-size: 13px;
							line-height: 14px;
							display: flex;
							align-items: center;
							margin-top: -6px;
							@include media("<=desktop") {
								font-size: 10px;
								line-height: 12px;
							}
							span {
								&:nth-child(1) {
									color: #32c315;
									margin-right: 10px;
								}
								&:nth-child(2) {
									margin-left: 10px;
									font-weight: bold;
								}
							}
						}
						.circle {
							width: 8rem;
							height: 8rem;
							border-radius: 50%;
							position: absolute;
							bottom: -3rem;
							right: -2rem;
							background-color: var(--primary-color);
							@include media("<=desktop") {
								width: 7rem;
								height: 7rem;
								right: -1.5rem;
								bottom: -2.5rem;
							}
						}
						.circle2 {
							width: 3rem;
							height: 3rem;
							border-radius: 50%;
							position: absolute;
							bottom: -1rem;
							right: 5rem;
							background-color: var(--black-color);
							@include media("<=desktop") {
								width: 2rem;
								height: 2rem;
								bottom: -0.5rem;
								right: 4rem;
							}
						}
					}
					.rangeError {
						border: 1px solid red;
					}
				}
			}

			.message {
				width: 100%;
				min-height: 10vh;
				border-radius: 10px;
				margin-top: 2rem;
				background: #f2f2f2;
				padding: 1.5rem 1rem;
				font-size: 0.9rem;
				line-height: 1.2rem;
			}

			button {
				margin: 2rem;
				background: var(--black-color);
				color: var(--white-color);
				border-radius: 10px;
				padding: 1.35rem 3rem;
				outline: none;
				border: none;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				cursor: pointer;
				font-size: 1rem;
				transition: 0.5s;
				@include media("<=desktop") {
					padding: 15px 30px;
					font-size: 20px;
				}
				&:hover {
					box-shadow: 0 0 10px rgba(169, 30, 30, 0.4);
				}
			}
		}
	}

	.hpNotice {
		margin-top: 2rem;
		margin-bottom: -1rem;
		p {
			line-height: 1.5rem;
			@include media("<=phone") {
				line-height: 2em;
				margin: 2em 0;
			}
			span {
				color: var(--primary-color);
				margin-right: 1rem;
			}
		}
	}
}
