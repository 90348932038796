@import "../../styles/breakpoints.scss";

.ttable {
	width: 100%;
	min-height: calc(100vh - 6rem);
	margin-top: 2rem;
	@include media("<=phone") {
		margin-top: 3rem;
		min-height: 100vh;
		width: 100%;
		overflow-x: scroll;
		overflow-y: auto;
	}

	.tableContent {
		margin-bottom: 2rem;
		@include media("<=phone") {
			width: 250%;
			margin-top: 1rem;
		}
		h3 {
			font-size: 12px;
		}
		.tdata {
			margin-top: 1.5rem;
			.tdata__data {
				display: flex;
				align-items: center;
				margin-bottom: 1rem;
				.logo {
					width: 45px;
					height: 45px;
					border-radius: 8px;
					margin-right: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1.2rem;
					@include media("<=phone") {
						width: 30px;
						height: 30px;
						border-radius: 6px;
						font-size: 1rem;
					}
				}
				.green {
					background: #e5f3f1;
					color: #0ba080;
				}
				.violet {
					background: #e8e6f0;
					color: #685490;
				}
				.red {
					background: #fdf4f6;
					color: #db335b;
				}
				.blue {
					background: #f0f3f7;
					color: #0b3a77;
				}
				.orange {
					background: #fff4e4;
					color: #f29f05;
				}
				.gray {
					background: #f2f2f2;
					color: gray;
				}
				.info {
					width: calc(30% - 50px);
					text-decoration: none;
					color: var(--black-color);
					@include media("<=phone") {
						width: calc(35% - 50px);
					}
					&__main {
						font-weight: 600;
						font-size: 1rem;
					}
					&__other {
						margin-top: 3px;
						font-size: 13px;
						color: gray;
						@include media("<=phone") {
							font-size: 12px;
						}
					}
				}
				.status {
					width: 15%;
					display: flex;
					align-items: center;
					@include media("<=phone") {
						width: 10%;
						font-size: 0.9rem;
					}
					&__color {
						margin-top: 3px;
						margin-right: 5px;
						width: 20px;
						height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						div {
							width: 10px;
							height: 10px;
							border-radius: 50%;
						}
						.green {
							background: #0ba080;
						}
						.violet {
							background: #685490;
						}
						.red {
							background: #db335b;
						}
						.blue {
							background: #0b3a77;
						}
						.orange {
							background: #f29f05;
						}
						.gray {
							background: gray;
						}
					}
				}
				.id {
					width: 5%;
					text-align: right;
					a,
					div {
						text-decoration: none;
						color: #ccc;
						transition: 0.3s;
						@include media("<=phone") {
							font-size: 0.9rem;
							display: none;
						}
						span {
							margin-right: 5px;
						}
						&:hover {
							color: var(--black-color);
						}
					}
					div {
						&:hover {
							color: #ccc;
						}
					}
				}
				.price {
					width: 20%;
					text-align: right;
					font-size: 1.2rem;
					font-weight: bold;
					padding-right: 1.5rem;
					@include media("<=phone") {
						width: 20%;
						text-align: left;
						padding-left: 1.5rem;
						padding-right: 0;
					}
					span {
						font-size: 0.9rem;
						font-weight: normal;
						margin-left: 5px;
					}
				}

				.option {
					width: 10%;
					display: flex;
					justify-content: flex-end;
					@include media("<=phone") {
						width: 15%;
					}
					div,
					a {
						text-decoration: none;
						color: var(--black-color);
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
						border-radius: 8px;
						cursor: pointer;
						transition: 0.3s;
						&:hover {
							transform: scale(1.1);
						}
					}
					a {
						margin-right: 1.2rem;
					}
					.cancel {
						color: red;
					}
					.edit {
						position: relative;
						svg {
							&:nth-child(2) {
								font-size: 10px;
								position: absolute;
								top: -5px;
								left: -5px;
								color: #685490;
							}
						}
					}
				}
			}
		}

		.popup {
			width: 100%;
			height: 100vh;
			background: rgba(0, 0, 0, 0.2);
			display: flex;
			align-items: center;
			justify-content: center;
			position: fixed;
			top: 0;
			left: 0;

			.popupWrapper {
				width: 30%;
				height: fit-content;
				background: var(--white-color);
				border-radius: 10px;
				padding: 2rem;
				@include media("<=phone") {
					width: 90%;
					padding: 2rem;
				}
				.icon {
					font-size: 2rem;
					margin-bottom: 2rem;
					@include media("<=phone") {
						font-size: 1.3rem;
						margin-bottom: 1rem;
					}
				}
				h3 {
					font-family: var(--font-roboto);
					font-size: 1.5rem;
					@include media("<=phone") {
						font-size: 1.3rem;
						margin-bottom: 10px;
					}
				}
				p {
					font-size: 1rem;
					color: gray;
				}
				.btnGroup {
					display: flex;
					margin-top: 4rem;
					@include media("<=phone") {
						margin-top: 3rem;
					}
					button {
						padding: 15px 40px;
						margin-right: 2rem;
						border-radius: 8px;
						outline: none;
						border: none;
						font-size: 1rem;
						cursor: pointer;
						transition: 0.3s;
						@include media("<=phone") {
							padding: 15px 20px;
							margin-right: 1.5rem;
						}
						&:last-child {
							background-color: #db335b;
							color: var(--white-color);
						}
						&:hover {
							box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
						}
					}
				}
			}
		}
	}

	// EMPTY TABLE
	.emptyTable {
		width: 100%;
		min-height: 60vh;
		padding: 2rem;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include media("<=phone") {
			min-height: fit-content;
			padding: 2rem 0;
		}
		div {
			width: 60%;
			text-align: center;
			@include media("<=phone") {
				width: 100%;
			}
			img {
				width: 50%;
			}
			h4 {
				width: fit-content;
				margin: auto;
				font-size: 2rem;
				@include media("<=phone") {
					margin-bottom: 0.9rem;
				}
			}
			p {
				width: 85%;
				margin: auto;
				text-align: center;
				justify-content: center;
				@include media("<=phone") {
					width: 100%;
					line-height: 1.2rem;
				}
				a {
					text-decoration: none;
					color: var(--primary-color);
					font-weight: 400;
				}
			}
		}
	}
}

/* Pagination */
.paginationBttns {
	width: 100%;
	height: 40px;
	margin-top: 4rem;
	list-style-type: none;
	display: flex;
	@include media("<=phone") {
		height: 30px;
		margin-top: 3rem;
		margin-bottom: 1rem;
	}
}
.paginationBttns a {
	text-decoration: none;
	padding: 10px 15px;
	margin: 8px;
	border-radius: 5px;
	border: 1px solid gray;
	color: #222;
	cursor: pointer;
	transition: 0.4s;
	@include media("<=phone") {
		padding: 7px;
		font-size: 12px;
	}
}
.paginationActive a {
	background: var(--primary-color);
	border: none;
	color: var(--white-color);
}
.nextsBtn,
.previousBtn {
	color: #fff !important;
	background: var(--black-color);
	transition: 0.3s;
	font-weight: bold;
	&:hover {
		color: var(--primary-color) !important;
	}
}
