/* ============== BREAKPOINTS ============= */
@import "../../styles/breakpoints.scss";

.userDetail {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.2);

	.wrapper {
		width: 100%;
		height: 100vh;
		background: transparent;
		position: relative;

		.info {
			width: 30%;
			height: 100vh;
			position: absolute;
			top: 0;
			right: 0;
			background: var(--white-color);
			animation: rightToLeft;
			animation-duration: 0.3s;
			overflow-y: auto;
			overflow-x: hidden;
			/* Hide scrollbar for IE, Edge and Firefox */
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			/* Hide scrollbar for Chrome, Safari and Opera */
			&::-webkit-scrollbar {
				display: none;
			}
			@include media("<=phone") {
				width: 100%;
			}

			//   ============= Content =============
			&__content {
				min-height: 10vh;
				margin-top: 5rem;
				padding: 7rem 2rem 2rem 2rem;
				position: relative;
				@include media("<=desktop") {
					margin-top: 2rem;
				}
				@include media("<=phone") {
					margin-top: 5rem;
					padding: 5rem 25px 25px 25px;
				}

				// ============= Close Button =============
				.closeButton {
					position: absolute;
					top: 5.5rem;
					right: 2rem;
					width: 50px;
					height: 50px;
					border-radius: 8px;
					background: var(--black-color);
					color: var(--primary-color);
					@include media(">=phone") {
						display: none;
					}
					@include media("<=phone") {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				// ============= Heading =============
				h2 {
					font-family: var(--font-roboto);
				}
				p {
					display: inline-block;
					padding: 5px 10px;
					border-radius: 5px;
					margin-top: 10px;
					font-size: 14px;
					font-weight: bold;
					@include media("<=desktop") {
						font-size: 12px;
					}
				}
				p.superAdmin {
					background: #e6f7f1;
					color: #30b182;
				}
				p.admin {
					background: #fff7e6;
					color: #f29f05;
				}
				p.customer {
					background: #f2f4f8;
					color: #385e91;
				}

				// ============= Personal Information =============
				.blocks {
					margin-top: 2rem;
					.block {
						width: 100%;
						display: flex;
						align-items: center;
						margin-bottom: 1rem;
						&__logo {
							width: 40px;
							height: 40px;
							border-radius: 8px;
							margin-right: 10px;
							display: flex;
							align-items: center;
							justify-content: center;
							box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
							@include media("<=desktop") {
								width: 32px;
								height: 32px;
								border-radius: 5px;
							}
						}
						&__name {
							font-size: 1rem;
							display: flex;
							align-items: center;
							@include media("<=desktop") {
								font-size: 14px;
							}
							span {
								padding: 0;
								margin-right: 10px;
								font-size: 12px;
								margin-top: 5px;
								@include media("<=desktop") {
									font-size: 10px;
								}
							}
							span.orange {
								color: #f7cb73;
							}
							span.green {
								color: #0ba080;
							}
							span.violet {
								color: #685490;
							}
							span.red {
								color: red;
							}
						}
					}
				}

				// ============= NID information =============
				.nidImage {
					justify-content: space-between;
					margin-top: 1rem;
					@include media("<=desktop") {
						margin-top: 10px;
					}
					.front,
					.back {
						width: 48%;
						height: 15vh;
						overflow: hidden;
						background: #f7f7f7;
						border-radius: 8px;
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						text-align: center;
						color: gray;
						img {
							width: 100%;
							border-radius: 8px;
						}
					}
				}

				// ============= Status change button =============
				.scButton {
					margin-top: 4rem;
					@include media("<=desktop") {
						margin-top: 2.5rem;
					}
					.buttonGroup {
						width: 100%;
						padding: 0 !important;
						.select {
							width: 100%;
							position: relative;
							&__title {
								width: 45%;
								height: 45px;
								background: var(--black-color);
								font-size: 1rem;
								color: var(--white-color);
								border-radius: 5px;
								display: flex;
								align-items: center;
								justify-content: center;
								padding: 20px;
								cursor: pointer;
								z-index: 10;
								@include media("<=desktop") {
									height: 50px;
									font-size: 14px;
								}
								@include media("<=phone") {
									width: 100%;
									font-size: 1rem;
									height: 55px;
								}
							}

							.loading {
								img {
									width: 20px;
									margin-right: 5px;
								}
							}
							// ============= Status options =============
							&__options {
								width: 45%;
								height: fit-content;
								background: var(--white-color);
								margin-top: 5px;
								transition: all 0.4s;
								border: 1px solid #ccc;
								border-radius: 0 0 5px 5px;
								position: absolute;
								bottom: 45px;
								left: 0;
								padding: 0;
								transform: scaleY(0);
								z-index: 10;
								@include media("<=desktop") {
									bottom: 50px;
								}
								@include media("<=phone") {
									bottom: 56px;
									width: 100%;
								}
								.option {
									display: block;
									padding: 15px 20px;
									display: flex;
									align-items: center;
									justify-content: space-between;
									cursor: pointer;
									transition: 0.4s;
									&:not(:last-child) {
										border-bottom: 1px solid #ccc;
									}
									@include media("<=desktop") {
										padding: 6px 10px;
										font-size: 14px;
									}
									@include media("<=phone") {
										padding: 15px 22px;
										font-size: 1rem;
									}
									&:hover {
										background: #f2f2f2;
									}
									.oname {
										width: 85%;
									}
								}
							}
							// ============= Status open & close animation =============
							@keyframes openMenu {
								0% {
									transform: scaleY(0);
								}
								50% {
									transform: scaleY(1.1);
								}
								100% {
									transform: scaleY(1);
								}
							}
							@keyframes closeMenu {
								0% {
									transform: scaleY(1);
								}
								50% {
									transform: scaleY(1.1);
								}
								100% {
									transform: scaleY(0);
								}
							}

							// ============= Status open & close option =============
							.open {
								animation: openMenu 300ms ease-in-out forwards;
							}
							.close {
								animation: closeMenu 300ms ease-in-out forwards;
							}
						}
					}
				}
			}
		}
	}
}

@keyframes rightToLeft {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
}
