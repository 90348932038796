@import "../../../styles/breakpoints.scss";

.home {
	width: 100%;
	min-height: 100vh;

	padding: 4em 0;

	// //////////////////////////////////////////////////////////////////////////////////////////////
	// ///////////////////////////////////////// COMMON ////////////////////////////////////////////
	h1.bigHeading {
		font-family: var(--font-roboto);
		font-size: 4rem;
		line-height: 4.5rem;
		margin-bottom: 2rem;
		@include media("<=desktop") {
			font-size: 3rem;
			line-height: 3.5rem;
		}
		@include media("<=phone") {
			font-size: 2rem;
			line-height: 2.5rem;
			margin-bottom: 12px;
			margin-top: -3rem;
		}
	}
	p.headingText {
		font-size: 20px;
		line-height: 2rem;
		@include media("<=desktop") {
			font-size: 1rem;
			line-height: 1.5rem;
		}
		@include media("<=phone") {
			padding: 1.5em 0;
			font-size: 1rem;
			line-height: 1.4rem;
		}
	}
	a.roundStyleLink {
		width: fit-content;
		display: flex;
		align-items: center;
		text-decoration: none;
		color: var(--black-color);
		margin-top: 4rem;
		@include media("<=phone") {
			margin: 2em 0;
		}
		.icon {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: var(--primary-color);
			color: var(--white-color);
			margin-right: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			@include media("<=desktop") {
				width: 40px;
				height: 40px;
				margin-right: 10px;
				font-size: 1rem;
			}
			@include media("<=phone") {
				width: 30px;
				height: 30px;
				font-size: 14px;
			}
		}
		.text {
			font-size: 2em;
			font-weight: 500;
			color: rgb(252, 82, 10);
			@include media("<=desktop") {
				font-size: 1rem;
			}
		}
	}
	h3.coloredHeading {
		color: var(--primary-color);
		font-size: 20px;
		font-weight: normal;
		@include media("<=phone") {
			padding: 4em 0;
		}
	}

	// //////////////////////////////////////////////////////////////////////////////////////////////
	// ///////////////////////////////////////// BANNER ////////////////////////////////////////////
	&__banner {
		width: 100%;
		height: 100vh;
		padding: 0 180px;
		display: flex;
		@include media("<=desktop") {
			padding: 0 130px;
		}
		@include media("<=phone") {
			margin-top: 6rem;
			flex-wrap: wrap;
			height: 85vh;
			padding: 0 20px;
		}

		.bannerText {
			width: 60%;
			display: flex;
			align-items: center;
			@include media("<=phone") {
				height: 45vh;
				width: 100%;
			}

			.textWrapper {
				width: 85%;
				@include media("<=phone") {
					width: 100%;
				}
			}
		}
		.bannerImage {
			width: 40%;
			position: relative;
			@include media("<=phone") {
				width: 100%;
				height: 40vh;
			}
			img {
				height: 80vh;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				@include media("<=phone") {
					width: 80%;
					height: auto;
				}
			}
		}
	}

	// //////////////////////////////////////////////////////////////////////////////////////////////
	// ////////////////////////////////////////// WHY //////////////////////////////////////////////
	&__why {
		width: 100%;
		padding: 180px;
		min-height: 100vh;
		background: var(--black-color);
		display: flex;
		@include media("<=desktop") {
			padding: 180px 130px;
		}
		@include media("<=phone") {
			padding: 3em 1em;
			flex-wrap: wrap;
			min-height: 90vh;
			flex-direction: column-reverse;
		}

		.whyImage {
			width: 45%;
			position: relative;
			@include media("<=phone") {
				width: 100%;
				height: 55vh;
			}
			img {
				height: 65vh;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@include media("<=desktop") {
					height: 60vh;
				}
				@include media("<=phone") {
					height: 30vh;
				}
			}
		}
		.whyText {
			width: 55%;
			padding-left: 2rem;
			display: flex;
			align-items: center;
			@include media("<=phone") {
				width: 100%;
				height: 40vh;
				padding-left: 0;
			}
			div {
				h1.bigHeading {
					color: var(--white-color);
				}
				p.headingText {
					color: var(--white-color);
				}
			}
		}
	}

	// //////////////////////////////////////////////////////////////////////////////////////////////
	// ////////////////////////////////////////// WHY //////////////////////////////////////////////
	&__offer {
		width: 100%;
		min-height: 100vh;
		display: flex;
		padding: 180px;
		@include media("<=desktop") {
			padding: 150px 130px;
		}
		@include media("<=phone") {
			min-height: 90vh;
			flex-wrap: wrap;
			padding: 50px 20px;
			padding-bottom: 0;
		}

		.offerText {
			width: 45%;
			@include media("<=phone") {
				width: 100%;
				height: fit-content;
			}
		}
		.offerOffer {
			width: 55%;
			padding-left: 100px;
			@include media("<=phone") {
				width: 100%;
				padding-left: 0;
			}
			.group {
				width: 100%;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				&:nth-child(1) {
					@include media("<=phone") {
						margin-bottom: 2rem;
					}
				}
			}
			.offer {
				width: 200px;
				height: 200px;
				margin-top: 50px;
				padding: 25px;
				border-radius: 10px;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				@include media("<=phone") {
					width: 150px;
					height: 120px;
					margin-top: 0;
					padding: 20px 10px;
				}
				h3 {
					margin-bottom: 15px;
					@include media("<=phone") {
						font-size: 1rem;
					}
				}
				p {
					color: var(--primary-color);
					span {
						margin-right: 10px;
					}
				}
				&:nth-child(odd) {
					margin-right: 50px;
					@include media("<=phone") {
						margin-right: 20px;
					}
				}
				&:nth-child(2) {
					background: var(--black-color);
					color: var(--white-color);
				}
				&:nth-child(3) {
					background: var(--black-color);
					color: var(--white-color);
				}
			}
		}
	}

	// //////////////////////////////////////////////////////////////////////////////////////////////
	// ////////////////////////////////////////// FAQ //////////////////////////////////////////////
	&__faq {
		width: 100%;
		height: 100vh;
		padding: 0 180px;
		display: flex;
		flex-wrap: wrap;
		@include media("<=desktop") {
			padding: 0 130px;
		}
		@include media("<=phone") {
			padding: 20px;
			height: fit-content;
		}

		.faqImage {
			width: 45%;
			@include media("<=phone") {
				width: 100%;
			}
			img {
				width: 100%;
			}
		}
		.faqContent {
			padding-left: 50px;
			width: 55%;
			@include media("<=phone") {
				width: 100%;
				padding-left: 0;
			}
			.accor {
				height: fit-content;
				border: none;
				margin-top: 2.5rem;
				margin-bottom: 20px;
				@include media("<=desktop") {
					margin-top: 2rem;
					margin-bottom: 15px;
				}
				@include media("<=phone") {
					margin-top: 1.5rem;
					margin-bottom: 10px;
				}

				&__item {
					border-bottom: 1px solid #ccc;
					&__acb {
						background: var(--white-color);
						padding: 20px 0;
						border: none;
						font-family: var(--font-roboto);
						font-size: 1.2rem;
						font-weight: bold;
						color: var(--black-color);
						cursor: pointer;
						@include media("<=phone") {
							padding: 20px 0;
							font-size: 1rem;
						}
					}
					&__text {
						padding-bottom: 1rem;
						p {
							font-size: 1rem;
							line-height: 1.8rem;
							color: var(--black-color);
						}
						@include media("<=desktop") {
							font-size: 14px;
						}
						@include media("<=phone") {
							font-size: 1rem;
							line-height: 1.4rem;
						}
					}
				}
			}
		}
	}
}
